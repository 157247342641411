import React from 'react'
import {DataGridPro, LicenseInfo, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector} from '@mui/x-data-grid-pro';
import {join, noop, filter, map, size} from 'lodash/fp';
import {connect} from "react-redux";
import {getAllMemoRankings} from "../../../reducers/adminSelectors";
import {exportAllMemoRankings, exportArbitratorMissingRankings, setTeamJuryStatus} from '../../../actions/adminActions'
import PropTypes from "prop-types";
import {format} from "date-fns";
import {Box, Button, Tooltip, Typography} from "@material-ui/core";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {SaveAlt} from "@material-ui/icons";
import { TEAM_ROLE_TYPE } from "../../../utils/constants";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {Check, DoNotDisturb} from "@mui/icons-material";
import {buildFile} from "../../../utils/fileUtils";


LicenseInfo.setLicenseKey(
    '19a99a1195c5f52fabd5f3310b083226T1JERVI6Mjk5MzAsRVhQSVJZPTE2NjQxNDA4NTIwMDAsS0VZVkVSU0lPTj0x',
);


const MemoRankingsTable = ({ role  = TEAM_ROLE_TYPE.CLAIMANT,
                               claimantRankings = [],
                               respondentRankings = [],
                               getAllMemoRankings = noop,
                               exportAllMemoRankings = noop,
                               exportArbitratorMissingRankings = noop,
                               setTeamJuryStatus = noop}) => {

    const [isFetching, setFetching] = React.useState(false)

    const rankings = role === TEAM_ROLE_TYPE.CLAIMANT ? claimantRankings : respondentRankings;
    const onSetTeamStatus = async (id, status) => {
        try {
            setFetching(true)
            await setTeamJuryStatus(id, status, role)
            setFetching(false)
        } catch (e) {
            //TODO ?
            setFetching(false)
        }
    }
    const rankingGridColumnDefinition = () => {
        return [
            {
                field: 'id',
                headerName: 'School Id',
                flex: 1,
                hide: true
            },
            {
                field: 'school',
                headerName: 'School',
                flex: 1
            },
            {
                field: 'teamAvgRank',
                headerName: 'Avg Rank',
                flex: 1
            },
            {
                field: 'rankCount',
                headerName: 'Rank Count',
                flex: 1,
                valueGetter: params => size(params.row.teamRankings)

            },
            {
                field: 'teamRankings',
                headerName: 'Ranking Values',
                flex: 1,
                valueGetter: params => join(', ', map(r => r.ranking, params.row.teamRankings))

            },
            {
                field: 'best3Count',
                headerName: 'Best 3 Count',
                flex: 1
            },
            {
                field: 'contact',
                headerName: 'Contact',
                flex: 1,
                hide: true
            },
            {
                field: 'email',
                headerName: 'Email',
                flex: 1,
                hide: true
            },
            {
                field: 'whichMoot',
                headerName: 'Not Eligible for Ranking',
                flex: 1,
                valueGetter: params => params.row.whichMoot === 'Vis Vienna' ? 'X' : ''
            },
            {
                field: 'addedToJuryRound',
                headerName: 'Added to Jury Round',
                type: 'boolean',
                flex: 1
            },
            {
                field: "actions",
                type: "actions",
                headerName: "Action",
                flex: 1,
                getActions: params => [
                    <GridActionsCellItem
                        icon={
                            <Tooltip title='Add to Jury'>
                                <Check htmlColor={params.row.addedToJuryRound === true ? '' : 'green'}/>
                            </Tooltip>
                        }
                        disabled={params.row.addedToJuryRound === true}
                        onClick={() => onSetTeamStatus(params.row.id, true)}
                        label="Accept"/>,
                    <GridActionsCellItem
                        icon={<Tooltip title='Remove from Jury'><DoNotDisturb
                            htmlColor={params.row.addedToJuryRound === false ? '' : 'red'}/></Tooltip>}
                        title='Deny'
                        disabled={params.row.addedToJuryRound === false}
                        onClick={() => onSetTeamStatus(params.row.id, false)}
                        label="Deny"/>
                ]
            }
        ]
    }

    React.useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setFetching(true)
                await getAllMemoRankings(role)
                setFetching(false)
            } catch (e) {
                //TODO ?
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const exportRankings = async () => {
        setFetching(true)
        const data = await exportAllMemoRankings(role)
        buildFile(data, `${role} Rankings`)
        setFetching(false)
    }

    const onExportArbitratorMissingRankings = async () => {
        setFetching(true)
        const data = await exportArbitratorMissingRankings(role)
        buildFile(data, `Arbitrators missing ${role} rankings`)
        setFetching(false)
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton className="toolbar-button" />
                <GridToolbarFilterButton className="toolbar-button" />
                <GridToolbarDensitySelector className="toolbar-button" />            
                <Button color="primary"
                        size="small"
                        startIcon={<SaveAlt/>}
                        aria-haspopup="menu"
                        onClick={exportRankings}>Export Rankings</Button>
                <Button color="primary"
                        size="small"
                        startIcon={<SaveAlt />}
                        aria-haspopup="menu"
                        onClick={onExportArbitratorMissingRankings}
                >Export arbitrators yet to rank</Button>
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{height: 700, width: '100%'}}>
            <DataGridPro
                loading={isFetching}
                rows={filter(r => r.role === role, rankings)}
                columns={rankingGridColumnDefinition(true)}
                disableSelectionOnClick
                getDetailPanelContent={({row}) => {
                    return <Box sx={{ padding: '1rem 2rem', backgroundColor:'#F0F0F0' }}>
                        <Typography variant="h6" gutterBottom component="div" style={{fontSize: '16px'}}>
                        Submitted Rankings
                        </Typography>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                            <TableRow>
                                <TableCell>Rank</TableCell>
                                <TableCell>Arbitrator</TableCell>
                                <TableCell>Arbitrator's Email</TableCell>
                                <TableCell>Uploaded Critique</TableCell>
                                <TableCell>Best 3</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {map(ranking => {      
                                    return (
                                    <TableRow key={ranking.id}>
                                    <TableCell component="th" scope="row">
                                        {ranking.ranking}
                                    </TableCell>
                                    <TableCell>{ranking.arbitrator}</TableCell>
                                    <TableCell><a href={`mailto:${ranking.arbitratorEmail}`}>{ranking.arbitratorEmail}</a></TableCell>                            
                                    <TableCell>{ranking.arbitratorUploadedCritique ? "Yes" : "No"}</TableCell>  
                                    <TableCell>{ranking.best3 ? "Yes" : "No"}</TableCell>            
                                    </TableRow>
                                    )
                                }, row.teamRankings)}                   
                            </TableBody>
                        </Table>
                    </Box>
                }}
                getDetailPanelHeight={({row}) => "auto"}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    )
}

MemoRankingsTable.propTypes = {
    getAllMemoRankings: PropTypes.func,
    exportAllMemoRankings: PropTypes.func,
    exportArbitratorMissingRankings: PropTypes.func
}

export default connect(
    (state, ownProps) => ({
        claimantRankings: state.admin.claimantRankings,
        respondentRankings: state.admin.respondentRankings,
      ...ownProps
    }),{
      getAllMemoRankings: getAllMemoRankings,
      exportAllMemoRankings: exportAllMemoRankings,
      setTeamJuryStatus: setTeamJuryStatus,
      exportArbitratorMissingRankings: exportArbitratorMissingRankings
    })( MemoRankingsTable)