import {connect} from "react-redux";
import PropTypes from "prop-types";
import {getSelectedTeam} from "../../reducers/teamSelectors";
import React, {useEffect, useState} from "react";
import {Container, CircularProgress, Snackbar} from "@material-ui/core";
import {find, get, noop, includes, chain, filter} from "lodash/fp";
import {Grid, Typography} from "@material-ui/core";
import Unauthorized from "../Unauthorized";
import {getCurrentMoot, getIsTeamMemberOpen} from "../../reducers/mootSelectors";
import {isNotEmpty} from "../../utils/funcUtils";
import {TEAM_OWNER, TEAM_MEMBER} from "../../consts/roles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem"; 

import { ACCEPTED } from '../../consts/status.js'
import VisaInvitationLetterForm from "./VisaInvitationLetterForm"
import {Alert} from "@mui/material";
import {uploadTeamVisaLetter} from "../../actions/fileActions";

const VisaInvitationLetter = ({
                      team,
                      user,
                      currentMoot,
                      getCurrentMoot = noop,
                      getSelectedTeam = noop,
                      getIsTeamMemberOpen = noop,
                      isTeamMemberOpen = {},
                      uploadTeamVisaLetter = noop
                  }) => {

    const [userInPerson, setUserInPerson] = useState()
    const [isLoading,setIsLoading] = useState(true);
    const [snackbarBusy, setSnackbarBusy] = useState(false);
    const [snackbarSeverity,setSnackbarSeverity] = useState('info');
    const [snackbarMessage,setSnackbarMessage] = useState('');

    const inPersonTeamMembers = (teamMembers) => {
        const members = filter(m => m.willBeInPerson === true, teamMembers)
        const membersSorted = chain(members)
            .map(m => ({
                id: m.id,
                userId: m.userId,
                name: `${m.firstName} ${m.lastName}`,
                lastName: m.lastName
            }))
            .sortBy(['lastName']) // Sort by 'lastName' ascending
            .value();  

      return membersSorted   
    }

    const userInPersonInfo = (user, teamMembers) => {
        return find(m => m.userId === user.id && m.willBeInPerson === true,teamMembers)
    }

    useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setIsLoading(true)
                await getCurrentMoot()
                const result = await getSelectedTeam()
                if (isNotEmpty(result)){
                    const userInPersonData = userInPersonInfo(user, result.members);
                    setUserInPerson(userInPersonData)
                }
                await getIsTeamMemberOpen()                 
                              
                setIsLoading(false)                
            } catch (e) {
                //TODO ?
                setIsLoading(false)
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
   
    
    //you have to have a team record and be accepted to be allowed see this page
    const isAllowed = get('status',team) === ACCEPTED

    const onlyGeneratedMessage = 'Visa invitation letters can only be generated for team members who have indicated that they will be physically present in Hong Kong in the Team List.'

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarBusy(false)
    };

    return (
        <Container style={{paddingBottom: '10rem'}}>
            {isLoading &&
                <CircularProgress/>
            }
            {!isLoading && !isAllowed && 
                <Unauthorized />
            }
            {!isLoading && isAllowed &&
                <>                
                <Grid container spacing={2}>
                    <Grid item xs={12}>                        
                        <Typography variant='h4' component='h1' style={{ margin: '1rem 0'}}>Visa Invitation Letter</Typography>
                        
                        {!includes(TEAM_OWNER,user.roles) && userInPerson === undefined &&
                            <Typography variant='body1' style={{margin:'0'}}>{onlyGeneratedMessage}</Typography>
                        }

                        {(includes(TEAM_OWNER,user.roles)|| userInPerson !== undefined) &&
                        <>
                        <Typography>Please complete the below fields in order to generate a Visa Invitation Letter. <strong><u>ALL</u></strong> fields must be completed.</Typography>
                        <Typography style={{marginBottom:'0'}}>NOTES:</Typography>
                        <List style={{listStyle:'disc', paddingInlineStart: '40px'}}> 
                            <ListItem style={{display:'list-item', padding:'0'}}>
                                <Typography variant='body1' style={{margin:'0'}}>Names are taken directly from the Team List. Please make sure each name matches the passport. Changes can be made directly on the Team List.</Typography>
                            </ListItem>
                            <ListItem style={{display:'list-item', padding:'0'}}>
                                <Typography variant='body1' style={{margin:'0'}}>{onlyGeneratedMessage}</Typography>
                            </ListItem>
                            <ListItem style={{display:'list-item', padding:'0'}}>
                                <Typography variant='body1' style={{margin:'0'}}>Participants are responsible for handling their own visa applications.</Typography>
                            </ListItem>  
                        </List> 
                        </>
                        }
                        <Snackbar open={snackbarBusy} onClose={handleClose} autoHideDuration={6000} >
                            <Alert severity={snackbarSeverity} sx={{ width: '100%' }}>
                                {snackbarMessage}
                            </Alert>
                        </Snackbar>
                    </Grid>
                </Grid>

                <VisaInvitationLetterForm user={user}
                                          userInPerson={userInPerson}
                                          team={team}
                                          type='TEAM_VISA'
                                          inPersonTeamMembers={inPersonTeamMembers}
                                          onUpload={(id,file,name,teamId) => uploadTeamVisaLetter(id,file,name,teamId)}
                                          onError={() => {
                    setSnackbarSeverity('error')
                    setSnackbarMessage('Error creating Visa Invitation Letter. Please try again.');
                    setSnackbarBusy(true)
                }} />

                </>
            }
        </Container>
    )
}


VisaInvitationLetter.propTypes = {
    getSelectedTeam: PropTypes.func,
    team: PropTypes.object,
    isTeamMemberOpen: PropTypes.object,
    getIsTeamMemberOpen: PropTypes.func
}

export default connect(
    (state) => ({
        currentMoot: state.moot.currentMoot,
        team: state.team.selectedTeam,
        isTeamMemberOpen: state.moot.currentMoot.isTeamMemberOpen,
        user: state.user,
    }), {
        getSelectedTeam: getSelectedTeam,
        getCurrentMoot: getCurrentMoot,
        getIsTeamMemberOpen: getIsTeamMemberOpen,
        uploadTeamVisaLetter: uploadTeamVisaLetter
    })(VisaInvitationLetter)