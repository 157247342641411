import React from 'react'
import {Divider, Tooltip} from '@material-ui/core'
import {Check, Pause, DoNotDisturb} from '@mui/icons-material';
import {MenuItem} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ActionMenu from "../../common/ActionMenu";
import {People} from "@material-ui/icons";

export default function AdminTeamActionMenu ({ status, onAccept, onHold, onDeny, onSilentlyHold, onSilentlyDeny, acceptWarning, onGoToTeamMembers }) {

    const listItemTextStyle = {fontFamily: '"Avenir Medium", Arial, Helvetica, sans-serif', fontWeight: 'bold'}
    const listItemIconStyle = {minWidth:'30px', marginBottom:'4px'}

    return (
      <ActionMenu>
           <MenuItem key='accept' disabled={status === 'accepted'} onClick={onAccept}>
              <ListItemIcon style={listItemIconStyle}>
                <Check htmlColor={status === 'accepted' ? '' : acceptWarning ? 'red' : 'green'}/>
              </ListItemIcon>
              <ListItemText>
                <Tooltip title={acceptWarning ? acceptWarning : 'Accept'}>
                    <span style={acceptWarning ? {color:'red'} : {} }>Accept</span>
                </Tooltip>
              </ListItemText>
          </MenuItem>

          <MenuItem key='hold' disabled={status === 'hold'} onClick={onHold}>
              <ListItemIcon style={listItemIconStyle}>
                <Pause htmlColor={status === 'hold' ? '' : 'darkgoldenrod'}/>
              </ListItemIcon>
              <ListItemText><span style={listItemTextStyle}>Hold</span></ListItemText>
          </MenuItem>
          <MenuItem key='deny' disabled={status === 'denied'} onClick={onDeny}>
              <ListItemIcon style={listItemIconStyle}>
                <DoNotDisturb htmlColor={status === 'denied' ? '' : 'red'}/>
              </ListItemIcon>
              <ListItemText><span style={listItemTextStyle}>Deny</span></ListItemText>
          </MenuItem>
          <Divider key='divider' />
          <MenuItem key='slientHold' onClick={onSilentlyHold}>
              <ListItemIcon style={listItemIconStyle}>
                <Pause fontSize="small" />
              </ListItemIcon>
              <ListItemText><span style={listItemTextStyle}>Silently Hold</span></ListItemText>
          </MenuItem>
          <MenuItem key='silentDeny' onClick={onSilentlyDeny}>
              <ListItemIcon style={listItemIconStyle}>
                <DoNotDisturb fontSize="small" />
              </ListItemIcon>
              <ListItemText><span style={listItemTextStyle}>Silently Deny</span></ListItemText>
          </MenuItem>     
          <MenuItem key='teamMembers' onClick={onGoToTeamMembers} style={{display: onGoToTeamMembers === undefined ? 'none' : 'flex'}}>
              <ListItemIcon style={listItemIconStyle}>
                  <People fontSize="small" />
              </ListItemIcon>
              <ListItemText><span style={listItemTextStyle}>Team Members</span></ListItemText>
          </MenuItem>
      </ActionMenu>
    );
  };