import React, {useState} from 'react'
import {DataGridPro, GridActionsCellItem, GridToolbarContainer, LicenseInfo, useGridApiRef} from '@mui/x-data-grid-pro';

import {countBy, filter, find, flatMap, includes, isEmpty, join, map, noop, size, startCase, uniqBy} from 'lodash/fp';
import {connect} from "react-redux";
import {getAllArbitrators, getAllMemoAssignments, getMemoAssignableTeams} from "../../../reducers/adminSelectors";
import {
    deleteArbitratorPairing,
    exportArbitratorWrittenPairings,
    generateWrittenArbitratorPairings,
    publishArbitratorPairings,
    saveArbitratorPairing,
    updateArbitratorPairing
} from "../../../actions/adminActions";

import PropTypes from "prop-types";
import {Box, Button, Snackbar, Tooltip, Typography} from "@material-ui/core";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {TEAM_ROLE_TYPE} from "../../../utils/constants";
import {Cancel, Delete, Edit, ExpandLess, ExpandMore, Save} from "@mui/icons-material";
import {Alert, Autocomplete, CircularProgress, TextField as MUITextField} from "@mui/material";
import {isNotEmpty, upsert} from "../../../utils/funcUtils";
import {Add, SaveAlt} from "@material-ui/icons";
import {withStyles} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import {useHistory} from "react-router-dom";
import {format} from "date-fns";
import TeamName from 'components/team/TeamName';
import {buildFile} from "../../../utils/fileUtils";


LicenseInfo.setLicenseKey(
    '19a99a1195c5f52fabd5f3310b083226T1JERVI6Mjk5MzAsRVhQSVJZPTE2NjQxNDA4NTIwMDAsS0VZVkVSU0lPTj0x',
);


const MemoAssignmentsTable = ({ router = {},
                                  role  = TEAM_ROLE_TYPE.CLAIMANT,
                                  claimantAssignments = [],
                                  respondentAssignments = [],
                                  getAllMemoAssignments = noop,
                                  getAllArbitrators = noop,
                                  getMemoAssignableTeams = noop,
                                  memoAssignableTeams = [],
                                  updateArbitratorPairing = noop,
                                  publishArbitratorPairings = noop,
                                  deleteArbitratorPairing = noop,
                                  generateWrittenArbitratorPairings = noop,
                                  exportArbitratorWrittenPairings = noop
                           }) => {
    const history = useHistory()

    const [isFetching, setIsFetching] = React.useState(false)
    const assignments = role === TEAM_ROLE_TYPE.CLAIMANT ? claimantAssignments : respondentAssignments;
    const [pairingSchools,setPairingSchools] = useState([])
    const [editRows,setEditRows] = useState([])
    const apiRef = useGridApiRef();
    const [saved,setSaved] = useState(false)
    const [summaryRows,setSummaryRows] = useState([])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSaved(false);
    };

    const exportPairings = async role => {
        try {
            setIsFetching(true)
            const data = await exportArbitratorWrittenPairings(role)
            buildFile(data, `Arbitrators ${role} Written Pairings`)            
        }
        finally {
            setIsFetching(false)
        }
    }
    function CustomToolbar() {
        const [isExpanded,setIsExpanded] = useState(false)
        const isDraft = find(a => a.draft,flatMap(a => a.pairings,assignments)) !== undefined

        const ColorButton = withStyles(() => ({
            root: {
                color: '#FFFFFF',
                backgroundColor: green[500],
                '&:hover': {
                    backgroundColor: green[700],
                },
            },
        }))(Button);

        return (
            <GridToolbarContainer  style={{justifyContent:'space-between'}}>
                <div style={{display: 'flex', gap: '40px'}}>
                <Button color="primary"
                        size="small"
                        startIcon={ isExpanded ? <ExpandLess /> : <ExpandMore />}                        
                        aria-haspopup="menu"
                        onClick={() => {
                            if (isExpanded) {
                                apiRef.current.setExpandedDetailPanels([])
                                setIsExpanded(false)
                            }else {
                                const rows = apiRef.current.getAllRowIds()
                                apiRef.current.setExpandedDetailPanels(rows)
                                setIsExpanded(true)
                            }
                        }}>{`${isExpanded ? 'Collapse' : 'Expand'} all rows`}</Button>
                <Button color="primary"
                        size="small"
                        style={{marginRight:'7rem'}}
                        startIcon={<SaveAlt />}
                        onClick={async () => {
                            await exportPairings(role)
                        }}
                        aria-haspopup="menu"
                >Export</Button>
                </div>
                <div style={{display: 'flex', gap: '40px'}}>                
                <Button color="primary"
                        size="small"
                        onClick={() => {
                            history.push(`${router.location.pathname}/add?role=${role}`)
                            }}
                        style={{alignSelf:'7rem'}}
                        startIcon={<Add />}
                        aria-haspopup="menu"
                >Add Arbitrator Evaluator</Button>
                <ColorButton variant='contained'
                             size='small'                             
                             onClick={async () => {
                                 try {
                                     setIsFetching(true)
                                     await publishArbitratorPairings(role)
                                     await getAllMemoAssignments(role,true)
                                     setSaved(true)
                                 } finally {
                                     setIsFetching(false)
                                 }
                             }}
                             color='primary'
                             disabled={isFetching || !isDraft}
                >Publish</ColorButton>
                </div>
            </GridToolbarContainer>
        );
    }
    const assignmentGridColumnDefinition = () => {
        return [
            {
                field: 'id',
                headerName: 'Arbitrator Id',
                flex: 1
            },
            {
                field: 'selectedArbitrator',
                headerName: 'Arbitrator',
                flex: 1,
                renderCell: params => params.row.arbitrator,                
                valueGetter: params => params.row.arbitrator
            },
            {
                field: 'arbitratorEmail',
                headerName: 'Email',
                flex: 1,
                renderCell: params => params.row.arbitratorEmail               
            },
            {
                field: 'jurisdiction',
                headerName: 'Jurisdiction',
                flex: 1
            },
            {
                field: 'affiliatedSchools',
                headerName: 'School Conflicts',
                flex: 1
            },
            {
                field: 'conflictingJurisdictions',
                headerName: 'Jurisdiction Conflicts',
                flex: 1
            },
            {
                field: 'draft',
                headerName: 'Draft',
                valueGetter: params => find(p => p.draft,params.row.pairings) !== undefined ? 'X'  : ''
            },
            {
                field: "actions",
                type: "actions",
                headerName: "Action",
                flex: 1,
                getActions: params => {
                    const rowId = params.id
                    let editActions = []

                    if (editRows.includes(rowId)) {
                        editActions = [<GridActionsCellItem
                            icon={<Save/>}
                            className="textPrimary"
                            color="inherit"
                            title='Save'
                            label="Save"
                            onClick={async () => {
                                try {
                                    setIsFetching(true)
                                    const oldArbId = rowId
                                    const modifiedPairings = filter(p => p.arbitratorId === oldArbId, pairingSchools)
                                    const result = await updateArbitratorPairing({oldArbId, modifiedPairings, role})
                                    setPairingSchools([...filter(p => p.arbitratorId !== rowId, pairingSchools)])

                                    let newPairings = [...params.row.pairings]
                                    for(let i =0; i < size(result); i++) {
                                        const newPairing = result[i]
                                        const team = find(t => t.id === newPairing.teamId, getTeamsAssignableForThisRole(memoAssignableTeams))
                                        const newRow = {
                                            id: newPairing.id,
                                            jurisdiction: team.jurisdiction,
                                            school: team?.school,
                                            teamId: newPairing.teamId
                                        }
                                        newPairings = upsert(['id', newPairing.id], newRow,  newPairings)
                                    }
                                    apiRef.current.updateRows([{ id: rowId, pairings: newPairings}]);

                                    addAssignedCountsToPairings(apiRef)

                                    setEditRows([...filter(e => e !== rowId, editRows)])                                    
                                    setSaved(true)
                                }finally{
                                    setIsFetching(false)
                                }
                            }}
                        />,
                            <GridActionsCellItem
                                icon={<Cancel/>}
                                label="Cancel"
                                title="Cancel"
                                className="textPrimary"
                                onClick={() => {
                                    setEditRows([...filter(e => e !== rowId, editRows)])
                                    setPairingSchools([...filter(p => p.arbitratorId !== rowId, pairingSchools)])
                                    apiRef.current.toggleDetailPanel(rowId)
                                }}
                                color="inherit"
                            />]
                    } else {
                        editActions = [
                            <GridActionsCellItem
                                icon={
                                    <Tooltip title='Edit'>
                                        <Edit/>
                                    </Tooltip>}
                                label="Edit"
                                title="Edit"
                                className="textPrimary"
                                onClick={() => {
                                    setEditRows([...editRows,rowId])                                    
                                    if(apiRef.current.getExpandedDetailPanels().includes(rowId) === false) {
                                        apiRef.current.toggleDetailPanel(rowId)
                                    }
                                }}
                                color="inherit"
                            />,
                            <GridActionsCellItem
                                title="Delete"
                                color='inherit'
                                icon={
                                    <Tooltip title='Delete'>
                                        <Delete/>
                                    </Tooltip>
                                }
                                onClick={async () => {
                                    const result = window.confirm(`Are you sure you want to delete the assignments for ${params.row.arbitrator}? `);
                                    if (result) {
                                        try {
                                            setIsFetching(true)
                                            await deleteArbitratorPairing({id: params.row.id,role})
                                            const result = await getAllMemoAssignments(role,true)
                                            setSummaryRows(buildSummary(result,getTeamsAssignableForThisRole(memoAssignableTeams)))
                                        }finally {
                                            setIsFetching(false)
                                        }
                                    }
                                }}
                                label="Delete" />
                        ];
                    }
                    return editActions
                }
            }
        ]
    }

    const totalsGridColumnDefinition = () => {
        return [
            {
                field: 'teamId',
                headerName: 'Team Id',
                hide: true,
                flex: 1
            },
            {
                field: 'school',
                headerName: 'School',
                flex: 1,
                renderCell: params => <TeamName team={getTeamFromId(params.row.teamId)} adminUse={true} />,
                valueGetter: params => (params.row.school)
                
            },
            {
                field: 'jurisdiction',
                headerName: 'Jurisdiction',
                flex: 1
            },
            {
                field: 'count',
                headerName: 'Total Assigned Count',
                flex: 1
            }
        ]
    }

    const getTeamsAssignableForThisRole = teams => {
        return filter(t => {
            return find (d => d.documentType === role,t.TeamDocuments)
        },teams)
    }

    React.useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setIsFetching(true)
                const result = await getAllMemoAssignments(role)
                await getAllArbitrators()
                const teams = await getMemoAssignableTeams()
                setSummaryRows(buildSummary(result,getTeamsAssignableForThisRole(teams)))
                setIsFetching(false)
            } catch (e) {
                //TODO ?
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isPairingModified = pairingId => {
        return getNewPairing(pairingId) !== undefined
    }
    const getNewPairing = pairingId => {
        return find(p => p.pairingId === pairingId,pairingSchools)
    }

    const getTeamFromNewPairing = pairingId => {
        return find(t => t.id === getNewPairing(pairingId)?.newTeamId,getTeamsAssignableForThisRole(memoAssignableTeams))
    }

    const addAssignedCountsToPairings = apiRef => {
        const rowIds = apiRef.current.getAllRowIds()
        const allRows = []
        for(let i=0; i< size(rowIds); i++) {
            const rowId = rowIds[i]
            const rowData = apiRef.current.getRow(rowId)
            allRows.push(rowData)
        }
        const mapped = flatMap(p => p.pairings, allRows)
        const grouped = countBy('teamId', mapped)
        const updated =  map(i => {
            return {
                id: i.id,
                pairings: map(p => ({...p, count: grouped[p.teamId]}), i.pairings)
            }
        }, allRows)
        apiRef.current.updateRows(updated)
        setSummaryRows(buildSummary(updated,getTeamsAssignableForThisRole(memoAssignableTeams)))
    }

    const buildSummary = (input,allTeams) => {
        const teamsThatAreAssigned = uniqBy('teamId',flatMap(p => p.pairings, input))
        const assignedTeamIds = map(t => t.teamId,teamsThatAreAssigned)
        const teamsWithoutRankings = filter(t => !includes(t.id,assignedTeamIds) ,allTeams)
        const unassignedTeamCounts = map(ua => ({
                id: ua.id,
                teamId: ua.id,
                school: ua.school,
                jurisdiction: ua.jurisdiction,
                count : 0
            }),teamsWithoutRankings)
        return [...teamsThatAreAssigned,...unassignedTeamCounts]
    }

    const getTeamFromId = (id) => {
        return find(t => id === t.id, getTeamsAssignableForThisRole(memoAssignableTeams))
    }

    return (
        <div style={{width: '100%'}}>
            { isEmpty(assignments)  && isFetching &&
                <CircularProgress />
            }
            { isEmpty(assignments) && !isFetching &&
                <>
                <Button
                     onClick={async () => {
                         try {
                             setIsFetching(true)
                             await generateWrittenArbitratorPairings(role)
                             const result = await getAllMemoAssignments(role)
                             setSummaryRows(buildSummary(result,getTeamsAssignableForThisRole(memoAssignableTeams)))
                         }finally{
                             setIsFetching(false)
                         }
                     }}
                 variant='contained'
                 size='large'
                 style={{marginTop: '2rem'}}>Generate draft {startCase(role.toLowerCase())} assignments</Button>
                </>
            }
            { isNotEmpty(assignments) &&
                <>
                    <DataGridPro
                        autoHeight
                        apiRef={apiRef}
                        loading={isFetching}
                        rows={assignments}
                        columns={assignmentGridColumnDefinition(true)}
                        initialState={{
                            columns: {
                              columnVisibilityModel: {
                                arbitratorEmail: false
                              },
                            },
                          }}
                        getDetailPanelContent={({row}) => {
                            const rowId = row.id
                            const rowArbitratorName = row.arbitrator
                            const isInEditMode = editRows.includes(rowId)
                            return <Box sx={{ padding: '1rem 2rem', backgroundColor:'#F0F0F0' }}>
                                <Typography variant="h6" gutterBottom component="div" style={{fontSize: '16px'}}>
                                {rowArbitratorName}'s {startCase(role.toLowerCase())} Memo Assignments
                                </Typography>
                                <Table size="small">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>Team Id</TableCell>
                                        <TableCell>School</TableCell>
                                        <TableCell>Jurisdiction</TableCell>
                                        <TableCell>Total Assigned Count</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {map(pairing => {
                                            return (
                                            <TableRow key={pairing.id}>
                                            <TableCell style={{width: '75px'}}>{
                                                isPairingModified(pairing.id) ?
                                                    <i>{ getNewPairing(pairing.id).newTeamId }</i>
                                                    : pairing.teamId}</TableCell>
                                            <TableCell style={{width: '300px'}}>
                                                {
                                                    isInEditMode &&
                                                    <Autocomplete
                                                        options={getTeamsAssignableForThisRole(memoAssignableTeams)}
                                                        getOptionLabel={value => find(t => value.id ? value.id === t.id : value === t.id,getTeamsAssignableForThisRole(memoAssignableTeams))?.school}
                                                        isOptionEqualToValue={(option, value) => option.id === value}
                                                        value={ isPairingModified(pairing.id) ? find(p => p.pairingId === pairing.id,pairingSchools)?.newTeamId : pairing.teamId}
                                                        autoSelect
                                                        onChange={(e,newValue) => {
                                                            if (newValue) {
                                                                const newPairingSchools = upsert(['pairingId', pairing.id], {
                                                                    arbitratorId: rowId,
                                                                    pairingId: pairing.id,
                                                                    oldTeamId: pairing.teamId,
                                                                    newTeamId: newValue.id
                                                                }, pairingSchools)
                                                                setPairingSchools(newPairingSchools)
                                                            }
                                                        }}
                                                        renderInput={(params) => <MUITextField {...params} variant="standard" label="School" required />}
                                                    />
                                                }
                                                 { !isInEditMode &&
                                                        <span><TeamName team={getTeamFromId(pairing.teamId)} adminUse={true} /> </span>
                                                 }
                                            </TableCell>
                                                <TableCell style={{width: '300px'}}>{ isPairingModified(pairing.id) ?
                                                <i>{getTeamFromNewPairing(pairing.id).jurisdiction}</i>
                                                : pairing.jurisdiction}</TableCell>
                                                <TableCell>
                                                    {pairing.count}
                                                </TableCell>
                                            </TableRow>
                                            )
                                        }, row.pairings)}
                                    </TableBody>
                                </Table>
                            </Box>
                        }}
                        getDetailPanelHeight={({row}) => "auto"}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                    />
                    <h3 style={{margin: '3rem 0 1rem 0'}}>{startCase(role.toLowerCase())} Memo Assigned Count</h3>
                    <div style={{marginBottom: '2rem', height: 500, width: '100%'}}>
                        <DataGridPro
                            loading={isFetching}
                            rows={summaryRows}
                            columns={totalsGridColumnDefinition()}
                            initialState={{
                                sorting: {
                                  sortModel: [{ field: 'count', sort: 'asc' }],
                                },
                              }}
                        />
                    </div>
                    <Snackbar open={saved} onClose={handleClose} autoHideDuration={6000}>
                        <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                        Changes saved
                        </Alert>
                    </Snackbar>
                </>
            }
        </div>
    )
}

MemoAssignmentsTable.propTypes = {
    getAllMemoAssignments: PropTypes.func,
    claimantAssignments: PropTypes.array,
    respondentAssignments: PropTypes.array,
    memoAssignableTeams: PropTypes.array
}

export default connect(
    (state, ownProps) => ({
        router: state.router,
        claimantAssignments: state.admin.claimantAssignments,
        respondentAssignments: state.admin.respondentAssignments,
        arbitrators: state.admin.arbitrators,
        memoAssignableTeams: state.admin.assignableTeams,
        ...ownProps
    }),{
        getAllMemoAssignments: getAllMemoAssignments,
        getAllArbitrators: getAllArbitrators,
        getMemoAssignableTeams: getMemoAssignableTeams,
        updateArbitratorPairing: updateArbitratorPairing,
        saveArbitratorPairing: saveArbitratorPairing,
        deleteArbitratorPairing: deleteArbitratorPairing,
        publishArbitratorPairings: publishArbitratorPairings,
        generateWrittenArbitratorPairings: generateWrittenArbitratorPairings,
        exportArbitratorWrittenPairings: exportArbitratorWrittenPairings
    })(MemoAssignmentsTable)