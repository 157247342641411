import {connect} from "react-redux";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {Button, Container, CircularProgress} from "@material-ui/core";
import {find, includes, noop} from "lodash/fp";
import {Grid} from "@material-ui/core";
import {TEAM_OWNER, TEAM_MEMBER, ARBITRATOR} from "../../consts/roles";
import Select from "../common/Select";
import TextField from "../common/TextInput";
import { downloadVisaInvitationLetter, generateVisaInvitationLetter } from "./downloadVisaInvitationLetter"
import {getCurrentMoot} from "../../reducers/mootSelectors";
import {uploadTeamVisaLetter} from "../../actions/fileActions";
import {toBase64} from "../team/UploadDocument/uploadUtils";

const VisaInvitationLetterForm = ({
                      team,
                      user,
                      currentMoot,
                      userInPerson,
                      inPersonTeamMembers,
                      type,
                      onError = noop,
                      onUpload = noop
                  }) => {
 
    const [isSaving,setIsSaving] = useState(false);
    const [isLoading,setIsLoading] = useState(true);
    const defaultVisa = { name: '', country: '', passportNumber: '', expirationDate:'', dateOfBirth:'', id: ''}
    const [visa,setVisa] = useState(defaultVisa)

    const onSave = async (e) => {

        try {
            e.preventDefault()
            setIsSaving(true)
            // Split this to generate and download seperate
            const file = await generateVisaInvitationLetter(visa,currentMoot)
            const text = await toBase64(file);
            const saved = await onUpload(visa.id,text.body,visa.name,team?.id)
            if (saved) {
                await downloadVisaInvitationLetter(visa, currentMoot,file,team?.id)
                setVisa(defaultVisa)
            }else{
                onError()
            }
        }
        finally {
            setIsSaving(false)
        }
    }

    const userFullName = (user) => {
        return `${user.firstName} ${user.lastName}`
    }    

    useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setIsLoading(true)
                await getCurrentMoot()
                //Set visa if user is not a team owner or type is 'ARBITRATOR_VISA' and the user will be in person
                if((!includes(TEAM_OWNER,user.roles) || type === 'ARBITRATOR_VISA') && userInPerson !== undefined) {                       
                    setVisa({...visa, name: userFullName(userInPerson), id: userInPerson.id})
                }    
                setIsLoading(false)
                           
            } catch (e) {
                //TODO ?
                setIsLoading(false)
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container style={{paddingBottom: '10rem'}}>

            {isLoading &&
                <CircularProgress />
            }

            {!isLoading && currentMoot.isVisaLetterOpen?.notOpenYet === true &&
                <h3 style={{marginTop: '1rem'}}>Visa Invitation Letter will open soon</h3>
            }
            {!isLoading && currentMoot.isVisaLetterOpen?.closed === true &&
                <h3 style={{marginTop: '1rem'}}>Visa Invitation Letter is closed</h3>
            }

            {!isLoading &&  currentMoot.isVisaLetterOpen?.closed === false &&  currentMoot.isVisaLetterOpen?.notOpenYet === false
                && (includes(TEAM_OWNER,user.roles) || userInPerson !== undefined) &&
            <form onSubmit={onSave} style={{marginBottom: '4rem'}}>
                <Grid container style={{marginTop: '2rem', maxWidth:'360px'}} spacing={1}>
                    <Grid item xs={12}>
                    {includes(TEAM_OWNER,user.roles) && type  === 'TEAM_VISA' &&
                        <Select name='name'
                                required
                                onChange={e => {
                                    setVisa({...visa, id: e.target.value, name: userFullName(find(t => t.id === e.target.value,team.members))})                                        
                                }}
                                value={visa.id}
                                label='Individual'
                                options={inPersonTeamMembers(team.members)}                                   
                        />
                    }                 
                    {( ((!includes(TEAM_OWNER,user.roles) && includes(TEAM_MEMBER,user.roles))) || (includes(ARBITRATOR,user.roles) && type === 'ARBITRATOR_VISA')) &&                            
                        <TextField
                            onChange={e => setVisa({
                                ...visa,
                                name: e.target.value
                            })}
                            required
                            disabled={true}
                            value={visa.name !== '' ? visa.name : (userInPerson.name ? userInPerson.name : '')}
                            label='Individual'
                        />               
                    }
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            onChange={e => setVisa({
                                ...visa,
                                country: e.target.value
                            })}
                            required
                            value={visa.country}
                            label='Country where passport is issued (Nationality)'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            onChange={e => setVisa({
                                ...visa,
                                passportNumber: e.target.value
                            })}
                            required
                            value={visa.passportNumber}
                            label='Passport Number'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            onChange={e => setVisa({
                                ...visa,
                                expirationDate: e.target.value
                            })}
                            required
                            value={visa.expirationDate}
                            label='Expiration date of passport'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            onChange={e => setVisa({
                                ...visa,
                                dateOfBirth: e.target.value
                            })}
                            required
                            value={visa.dateOfBirth}
                            label='Date of Birth'
                        />
                    </Grid>
                    <Grid item xs={12} md={12} style={{display:'flex', alignItems: 'center'}}>
                        <Button variant='contained'
                                type='submit'
                                color='primary'
                                disabled={isSaving}>GENERATE</Button>
                        {isSaving &&
                            <CircularProgress size={30} style={{marginLeft: '2rem'}}/>
                        }
                    </Grid>
                </Grid>
        
            </form> 
            }
        </Container>
    )
}


VisaInvitationLetterForm.propTypes = {
    team: PropTypes.object,
}

export default connect(
    (state,ownProps) => ({
        currentMoot: state.moot.currentMoot,
        user: state.user,
        team: ownProps.team,
        onError: ownProps.onError,
        onUpload: ownProps.onUpload,
        type: ownProps.type,
    }), {
        getCurrentMoot: getCurrentMoot,
        uploadTeamVisaLetter: uploadTeamVisaLetter
    })(VisaInvitationLetterForm)
