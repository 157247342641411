import * as Actions from './actionTypes/globalActionTypes'
import * as api from '../api'
import makeAction from "./actionCreator";


export const getGlobals = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_GLOBALS_REQUESTED))

    try {
        const result = await api.getGlobals()
        dispatch(makeAction(Actions.GET_GLOBALS_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.GET_GLOBALS_FAILED, {}, e))
    }
}
