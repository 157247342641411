import {VisaInvitationLetterTemplate} from "./VisaInvitationLetterTemplate";
import {pdf} from "@react-pdf/renderer";
import React from "react";

import {clientsideDownloadFile} from "../../utils/fileUtils";

export const downloadVisaInvitationLetter = async (visa, currentMoot, file, teamId) => {
    const fullName = (teamId !== undefined ? `${currentMoot.id}_TEAM_${teamId}_${visa.id}` :`${currentMoot.id}_ARBITRATOR_${visa.id}`) + "_VEM_Visa_Invitation_Letter";
    clientsideDownloadFile(file,fullName)
}

export const generateVisaInvitationLetter = async (visa,currentMoot) => {
    const visaInvitationLetter = <VisaInvitationLetterTemplate visa={visa} currentMoot={currentMoot} />
    const data = await pdf(visaInvitationLetter).toBlob();
    return data
}
