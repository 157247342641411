import makeAction from "./actionCreator";
import * as Actions from "./actionTypes/fileActionTypes";
import * as api from "../api";

export const uploadTeamVisaLetter = (id, file,name,teamId, overrideCode) => async (dispatch, getState) => {
    dispatch(makeAction(Actions.SAVE_VISA_REQUESTED))

    try {
        const result = await api.uploadTeamVisaLetter(id,file,name,teamId,overrideCode);
        dispatch(makeAction(Actions.SAVE_VISA_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.SAVE_VISA_FAILED, {}, e))
    }
}


export const uploadArbitratorVisaLetter = (id, file, name,overrideCode) => async (dispatch, getState) => {
    dispatch(makeAction(Actions.SAVE_VISA_REQUESTED))

    try {
        const result = await api.uploadArbitratorVisaLetter(id,file,name,overrideCode)
        dispatch(makeAction(Actions.SAVE_VISA_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.SAVE_VISA_FAILED, {}, e))
    }
}


export const getFileUrl = (type,key) => async (dispatch, getState) => {
    dispatch(makeAction(Actions.GET_FILE_URL_REQUESTED))

    try {
        const result = await api.getFileUrl(type,key)
        dispatch(makeAction(Actions.GET_FILE_URL_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.GET_FILE_URL_FAILED, {}, e))
    }
}