import {utils, writeFile} from "xlsx";
import {format} from "date-fns";

export const getVisaLetterFileName = (type,currentMootId,id,teamId) => {
    const fileNamePrefix = type === 'TEAM_VISA' ? `${currentMootId}_TEAM_${teamId}_${id}` :`${currentMootId}_ARBITRATOR_${id}`
    return `${fileNamePrefix}_VEM_Visa_Invitation_Letter.pdf`;
}

// Creates an excel file from tab delimited data
export const triggerFileDownloadFromUrl = (url, fileName) => {
    // Test download attribute first
    // https://github.com/eligrey/FileSaver.js/issues/193
    if ('download' in HTMLAnchorElement.prototype) {
        // Create a new anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;

        // Programmatically trigger a click on the anchor element
        // Useful if you want the download to happen automatically
        // Without attaching the anchor element to the DOM
        a.click();
    }
}

export const clientsideDownloadFile = (blob, fileName) => {
    // Test download attribute first
    // https://github.com/eligrey/FileSaver.js/issues/193
    if ('download' in HTMLAnchorElement.prototype) {
        // Create an object URL for the blob object
        const url = URL.createObjectURL(blob);

        // Create a new anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;

        // Programmatically trigger a click on the anchor element
        // Useful if you want the download to happen automatically
        // Without attaching the anchor element to the DOM
        a.click();

        // https://github.com/eligrey/FileSaver.js/issues/205
        setTimeout(() => {
            URL.revokeObjectURL(url);
        });
    }
}

export const buildFile = (data, filename) => {
    // Extract headers from the first line
    const headers = data[0].split("\t");

    // Process the rest of the lines
    const jsonObject = data.slice(1).map(row => {
        // Normalize the row by replacing double `\t` with `\t \t` to handle empty values
        const normalizedRow = row.replace(/\t\t/g, "\t \t");

        // Split by `\t` and trim spaces
        const values = normalizedRow.split("\t").map(value => value.trim());

        return headers.reduce((obj, header, index) => {
            obj[header] = values[index] || null; // Use null for missing values
            return obj;
        }, {});
    });

    //creates an empty workbook with no worksheet
    let wb = utils.book_new(), //creates a new workbook

        //coverts JSON data into a sheet
        ws = utils.json_to_sheet(jsonObject);

    utils.book_append_sheet(wb, ws, "Sheet1")

    const fullName = `${filename} - ${format(new Date(), 'yyyy-MM-dd')}.xlsx`;

    // package and release data (`writeFile` tries to write and save an XLSX file)
    writeFile(wb, fullName)
}