import {format, parseISO} from "date-fns";
import React, {useEffect, useState} from "react";
import _ from "lodash/fp";
import {Tooltip, Typography, withStyles} from '@material-ui/core';

export const getHoursDifference = () => {
  const localTime = new Date()
  //time zone value for Hong Kong
  const timeZoneFromDB = +8.0
  //get the timezone offset from local time in minutes
  const tzDifference = timeZoneFromDB * 60 + localTime.getTimezoneOffset()
  //convert the offset to milliseconds, add to localTime, and make a new Date
  const hongKongTime = new Date(localTime.getTime() + tzDifference * 60 * 1000)

  const hoursDifferenceBetweenHk = Math.abs(hongKongTime - localTime) / 36e5
  return hoursDifferenceBetweenHk
}

export const getFormattedTimeDifference = (hours, thirty) => {
  const hoursDifference = getHoursDifference()
  const date = new Date()
  date.setHours(hours - hoursDifference)
  const finalHours = date.getHours()
  if (finalHours === 0) return '12:00 AM'
  if (finalHours > 12) return finalHours - 12 + (thirty ? ':30 PM' : ':00 PM')
  return finalHours + (thirty ? ':30 AM' : ':00 AM')
}


export const clarificationGridColumnDefinition = (addTeam = false) => {

 let columnDef = [
  {
    field: 'category',
    headerName: 'Category',
    flex: 1
  },
  {
    field: 'clarificationSought',
    headerName: 'Clarification Sought',
    flex: 1
  },
  {
    field: 'rationale',
    headerName: 'Rationale',
    flex: 1
  },
  {
    field: 'createdAt',
    headerName: 'Date Submitted',
    width: 120,
    renderCell: params => format(parseISO(params.row.createdAt),'yyyy-MM-dd'),
    valueGetter: params => format(parseISO(params.row.createdAt),'yyyy-MM-dd HH:mm O')
  }
  ]

  if (addTeam){

    columnDef = [ {
      field: "",
      headerName: "School",
      flex : 1,
      valueGetter: params => params.row.team.school
    },...columnDef]
  }
  return columnDef
}


export const friendlyRoundName = roundType =>{
    switch (roundType){
        case 'General Rounds':
            return 'General Round'
        case 'Elimination Rounds: Round of 32':
            return 'Round of 32'
        case 'Elimination Rounds: Round of 16':
            return 'Round of 16'
        case 'Elimination Rounds: Quarter-Finals':
            return 'Quarterfinal'
        case 'Elimination Rounds: Semi-Finals':
            return 'Semifinal'
        case 'Elimination Rounds: Finals':
            return 'final'
        default:
            return roundType
    }
}

export const asHKCurrency = (value, fractionDigits = 2) =>{
    const valueLocaleString = value.toLocaleString('zh-HK',{style: 'currency', currency: 'HKD',minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits})
    const currencyWithSpace = valueLocaleString.split("$").join("$ ");
    return currencyWithSpace
}

export const formatCurrency = (value, prependText) => {
    const formattedValue = asHKCurrency(Math.abs(value))
    return <><span>{prependText} </span><span className={value < 0 ? 'negative-value' : 'positive-value'}>{value < 0 ? `(${formattedValue})` : `${formattedValue}`}</span></>

}


const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 180,
    userSelect: 'text',
  },
}))(Tooltip);

export const CustomTooltip = ({ content, tooltipContent = content }) => {
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <Typography color="inherit" style={{ marginBottom: 0, fontWeight: 'bold', fontSize: '0.875rem' }}>
            {tooltipContent}
          </Typography>
        </React.Fragment>
      }
      interactive
      enterTouchDelay={0}
      arrow
      html="true"
    >
      <div>
        {content}
      </div>
    </HtmlTooltip>
  );
};


export const shouldOverride = (code,currentMoot) => {
    return !_.isNil(code) && code === currentMoot?.openOverrideCode
}

/* Local Storage */
export const getStorageValue = (key, defaultValue) => {
  // getting stored value
  const saved = localStorage.getItem(key);
  const initial = JSON.parse(saved);
  return initial || defaultValue;
}

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};