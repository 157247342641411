import Container from '@material-ui/core/Container'
import React, {useEffect, useState} from 'react'
import {noop, size,get, startCase, toLower, filter} from "lodash/fp";
import {connect} from 'react-redux'
import PropTypes from "prop-types";
import {CircularProgress, Typography} from "@material-ui/core";
import Unauthorized from "../Unauthorized";
import {getSelectedArbitrator, getArbitratorClaimantPairings, getArbitratorRespondentPairings } from "../../reducers/arbitratorSelectors";

import './ArbitratorPairings.css';
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TabPanel from '../common/TabPanel'
import {makeStyles} from "@material-ui/core/styles";
import WrittenRankings from "./writtenRankings";
import { TEAM_ROLE_TYPE } from "../../utils/constants";
import JuryRankings from "./juryRankings";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },
}))



const RankingTypography = ({teamRoleType}) => {
    return (
        <>
        <Typography style={{margin: '1rem 0'}}>Thank you again for agreeing to take the time to review, rank, and (hopefully) provide comments on the Vis East Moot teams’ {toLower(teamRoleType)}’s written memoranda. Submissions of written memoranda are an important part of the Vis East Moot process and the teams put a lot of work into the research and drafting of such memoranda.</Typography>
            <Typography style={{ margin: '1rem 0'}}>In this section, you will find the list of {startCase(toLower(teamRoleType))}'s Memoranda assigned to you to review, comment, and rank. Please note that the ranking system used by the Vis East Moot is <strong>different</strong> from that of the Vis Vienna Moot. For the Vis East Moot, the memoranda are ranked <strong>from 0 to 5</strong>, from <strong>worst to best</strong>. To be clear, please rank the <strong>best memoranda</strong> in the group of memoranda that you received with a score of <strong>5</strong> and the <strong>worst memoranda</strong> with a score of <strong>0</strong>. There can be <u>no ties</u> and each memorandum must be ranked a number (0 to 5). If you come across a particularly great memorandum that you believe may be in contention for the "winning memorandum" in the entire Vis East Moot, please tick the box to the right of the ranking, which indicates "Possible best 3 in the entire Moot?". Please note that you can only tick this box for three (3) of the memoranda.</Typography>
            <Typography style={{ margin: '1rem 0'}}>Please download the Critique Form <a href={` ${toLower(teamRoleType) === "claimant" ? "https://public-mycisgmoot-documents.s3.amazonaws.com/Readers_Critique_Claimant_Form_VEM22.pdf" : "https://public-mycisgmoot-documents.s3.amazonaws.com/Readers_Critique_Respondent_Form_VEM22.pdf"}` } target="_blank" rel="noreferrer">here</a> to complete while you are reviewing each {startCase(toLower(teamRoleType))} Memorandum. Please note that each memorandum requires a separate Critique Form.  Your comments are remitted separately from your rankings, so that your ranking of each team's work, although visible to the organizers, is not revealed to the teams. On the other hand, you may wish to identify yourself to the teams when making specific comments or suggestions. If you do not wish to identify your comments, simply do not enter your name on the Critique Form. We will know that you've submitted it once you have uploaded such form to your Arbitrator Account. If you become aware that a team has violated one of the mandatory Vis East Moot Rules regarding format of the Memoranda (see, e.g., paragraphs 50 et seq. of the Rules), please note it in the comment portion of the Critique Form. A violation of mandatory format disqualifies the memorandum from the competition for Best Memorandum.
            </Typography>
        </>
    )
}


const JuryRankingTypography = ({teamRoleType, totalTeamCount = 0}) => {
    return (
        <>
            <Typography style={{margin: '1rem 0'}}>Thank you again for having agreed to act as a "Juror" to review and rank the final memoranda for {teamRoleType}.</Typography>
            <Typography style={{ margin: '1rem 0'}}>Please review each of the memoranda listed below and tick on the ranking for each memoranda from 1 to {totalTeamCount}, from worst to best. To be clear, please rank the worst memoranda of the group with a score of 1 and the best memoranda of the group with a score of  {totalTeamCount}.
            </Typography>
            <Typography style={{ margin: '1rem 0'}}>Please note that there can be <strong>no</strong> ties.
            </Typography>
            <Typography style={{ margin: '1rem 0'}}>Please ensure that your final rankings are submitted <strong><u>no later than {teamRoleType === 'CLAIMANT' ? '11 February 2024' : '10 March 2024' }</u></strong>.</Typography>
        </>
    )
}

const ArbitratorPairings = ({
                                arbitrator,
                                arbitratorClaimantRankings = [],
                                arbitratorRespondentRankings = [],
                                arbitratorClaimantPairings,
                                arbitratorRespondentPairings,
                                getSelectedArbitrator = noop,
                                getArbitratorClaimantPairings = noop,
                                getArbitratorRespondentPairings = noop,
                                claimantJuryTeams = [],
                                respondentJuryTeams = []
                            }) => {

    const [tabValue, setTabValue] = useState(0)

    const classes = useStyles()
    const handleTabChange = (event, newValue) => setTabValue(newValue)
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        async function getData() {
            setIsLoading(true)            
            await getSelectedArbitrator()
            await getArbitratorClaimantPairings()
            await getArbitratorRespondentPairings()
            setIsLoading(false)
        }
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const isAllowed = get('status', arbitrator) === 'accepted'  
    
    

    return (
        <Container style={{paddingBottom: '10rem'}}>
            {isLoading &&
            <CircularProgress/>
            }
            {!isLoading && !isAllowed &&
            <Unauthorized/>
            }
            {!isLoading && isAllowed &&
            <Container maxWidth='xl' className={classes.root} style={{padding: '0'}}>
                <Typography variant='h4'>Ranking of Written Submissions</Typography>
                <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label='Claimant' disabled={!Boolean(arbitratorClaimantPairings.length)}/>
                    <Tab label='Respondent' disabled={!Boolean(arbitratorRespondentPairings.length)}/>
                    <Tab label='Final Rankings Claimant' disabled={!get('selectedToRankJuryRoundClaimants', arbitrator)} />                    
                    <Tab label='Final Rankings Respondent' disabled={!get('selectedToRankJuryRoundRespondents', arbitrator)}/>                                    
                </Tabs>              
                <TabPanel value={tabValue} index={0}>
                    {Boolean(arbitratorClaimantPairings.length) &&
                        <>
                            <RankingTypography teamRoleType={TEAM_ROLE_TYPE.CLAIMANT} />
                            <WrittenRankings teamRoleType={TEAM_ROLE_TYPE.CLAIMANT} arbitratorRankings={arbitratorClaimantRankings} arbitratorPairings={arbitratorClaimantPairings} />
                        </>
                    }
                    {!Boolean(arbitratorClaimantPairings.length) &&
                        <Typography style={{margin: '1rem 0'}}>Not assigned to review claimant memorandums</Typography>
                    }
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    {Boolean(arbitratorRespondentPairings.length) &&
                        <>
                            <RankingTypography teamRoleType={TEAM_ROLE_TYPE.RESPONDENT} />
                            <WrittenRankings teamRoleType={TEAM_ROLE_TYPE.RESPONDENT} arbitratorRankings={arbitratorRespondentRankings} arbitratorPairings={arbitratorRespondentPairings} />
                        </>
                    }
                    {!Boolean(arbitratorRespondentPairings.length) &&
                        <Typography style={{margin: '1rem 0'}}>Not assigned to review respondent memorandums</Typography>
                    }
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    {get('selectedToRankJuryRoundClaimants', arbitrator) === true &&
                    <>
                        <JuryRankingTypography teamRoleType={TEAM_ROLE_TYPE.CLAIMANT}
                                               totalTeamCount={size(claimantJuryTeams)}/>
                        <JuryRankings teamRoleType={TEAM_ROLE_TYPE.CLAIMANT}
                                      juryRankings={filter(r => r.teamRole === TEAM_ROLE_TYPE.CLAIMANT, arbitrator.JuryRankings)}/>
                    </>
                    }
                    {!get('selectedToRankJuryRoundClaimants', arbitrator) &&
                        <Typography style={{margin: '1rem 0'}}>Not assigned to review final claimant memorandums</Typography>
                    }
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    {get('selectedToRankJuryRoundRespondents', arbitrator) === true &&
                        <>
                            <JuryRankingTypography teamRoleType={TEAM_ROLE_TYPE.RESPONDENT}
                                                   totalTeamCount={size(respondentJuryTeams)}/>
                            <JuryRankings teamRoleType={TEAM_ROLE_TYPE.RESPONDENT}
                                          juryRankings={filter(r => r.teamRole === TEAM_ROLE_TYPE.RESPONDENT, arbitrator.JuryRankings)} />
                        </>
                    }
                    {!get('selectedToRankJuryRoundRespondents', arbitrator) &&
                        <Typography style={{margin: '1rem 0'}}>Not assigned to review final respondent memorandums</Typography>
                    }
                </TabPanel>             
            </Container>
            }            
        </Container>
    )
}


ArbitratorPairings.propTypes = {
    arbitrator: PropTypes.object,
    getSelectedArbitrator: PropTypes.func,
    getArbitratorClaimantPairings: PropTypes.func,
    getArbitratorRespondentPairings: PropTypes.func,
}

export default connect(
    (state, ownProps) => ({
        arbitrator: state.arbitrator.selectedArbitrator,
        arbitratorClaimantPairings: state.arbitrator.claimantPairings,
        arbitratorClaimantRankings: state.arbitrator.claimantRankings,
        arbitratorRespondentRankings: state.arbitrator.respondentRankings,
        arbitratorRespondentPairings: state.arbitrator.respondentPairings,
        claimantJuryTeams: state.arbitrator.claimantJuryTeams,
        respondentJuryTeams: state.arbitrator.respondentJuryTeams,
        ...ownProps
    }),
    {
        getSelectedArbitrator: getSelectedArbitrator,
        getArbitratorClaimantPairings: getArbitratorClaimantPairings,
        getArbitratorRespondentPairings: getArbitratorRespondentPairings
    }
)(ArbitratorPairings)