import * as Actions from './actionTypes/teamActionTypes'
import * as api from '../api'
import makeAction from "./actionCreator";

export const saveClarificationRequest = (clarification,overrideCode) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SAVE_TEAM_CLARIFICATION_REQUESTED, {clarification, overrideCode}))

    try {
        const result = await api.saveClarificationRequest(clarification,overrideCode)
        dispatch(makeAction(Actions.SAVE_TEAM_CLARIFICATION_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.SAVE_TEAM_CLARIFICATION_FAILED, {},e))
    }
}

export const getExistingClarifications = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_TEAM_CLARIFICATIONS_REQUESTED))

    try {
        const result = await api.getExistingClarifications()
        dispatch(makeAction(Actions.GET_TEAM_CLARIFICATIONS_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_TEAM_CLARIFICATIONS_FAILED, {},e))
    }
}

export const getTeam = () =>  async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_TEAM_REQUESTED))

    try {
        const result = await api.getTeam()
        dispatch(makeAction(Actions.GET_TEAM_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_TEAM_FAILED, {},e))
    }
}

export const getTeamById = teamId => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_TEAM_REQUESTED,{teamId}))
    try {
        const result = await api.getTeam(teamId)
        dispatch(makeAction(Actions.GET_TEAM_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_TEAM_FAILED, {},e))
    }
}

export const saveInvitedTeamMember = (cognitoId,inviteCode) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SAVE_INVITED_TEAM_MEMBER_REQUESTED, {cognitoId,inviteCode}))

    try {
        const result = await api.saveInvitedTeamMember(cognitoId,inviteCode)
        dispatch(makeAction(Actions.SAVE_INVITED_TEAM_MEMBER_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.SAVE_INVITED_TEAM_MEMBER_FAILED, {},e))
    }
}

export const saveTeamMember = (newTeamMember,override,teamId) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SAVE_TEAM_MEMBER_REQUESTED, {newTeamMember,override,teamId}))

    try {
        const result = await api.saveTeamMember(newTeamMember,override,teamId)
        dispatch(makeAction(Actions.SAVE_TEAM_MEMBER_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.SAVE_TEAM_MEMBER_FAILED, {},e))
    }
}

export const deleteTeamMember = (id,teamId) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.DELETE_TEAM_MEMBER_REQUESTED))

    try {
        const result = await api.deleteTeamMember(id,teamId)
        dispatch(makeAction(Actions.DELETE_TEAM_MEMBER_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.DELETE_TEAM_MEMBER_FAILED, {},e))
    }
}

export const uploadDocument = (type, whichMoot, document, overrideCode) => async (dispatch, getState) => {
    dispatch(makeAction(Actions.UPLOAD_TEAM_DOCUMENT_REQUESTED, {type, whichMoot, document,overrideCode}))

    try {
        const result = await api.uploadTeamDocument(type,whichMoot,document,overrideCode)
        dispatch(makeAction(Actions.UPLOAD_TEAM_DOCUMENT_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.UPLOAD_TEAM_DOCUMENT_FAILED, {},e))
        return null
    }
}

export const getSelectedTeamsPairings = () =>  async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_SELECTED_TEAMS_PAIRINGS_REQUESTED))

    try {
        const result = await api.getSelectedTeamsPairings()
        dispatch(makeAction(Actions.GET_SELECTED_TEAMS_PAIRINGS_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_SELECTED_TEAMS_PAIRINGS_FAILED, {},e))
    }
}

export const saveTeamOrder = (teamId,orderJSON, description, amount,type) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SAVE_TEAM_ORDER_REQUESTED))

    try {
        const result = await api.saveTeamOrder(teamId,orderJSON, description, amount,type)
        dispatch(makeAction(Actions.SAVE_TEAM_ORDER_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.SAVE_TEAM_ORDER_FAILED, {},e))
    }
}

export const getTeamPaymentHistory = (teamId) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_TEAM_PAYMENT_HISTORY_REQUESTED))

    try {
        const result = await api.getTeamPaymentHistory(teamId)
        dispatch(makeAction(Actions.GET_TEAM_PAYMENT_HISTORY_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_TEAM_PAYMENT_HISTORY_FAILED, {},e))
    }
}

export const generateInvoice = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GENERATE_TEAM_INVOICE_REQUESTED))

    try {
        const result = await api.generateTeamInvoice()
        dispatch(makeAction(Actions.GENERATE_TEAM_INVOICE_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.GENERATE_TEAM_INVOICE_FAILED, {},e))
    }
}


export const createStripePaymentIntent = amount => async (dispatch,getState) => {
    dispatch(makeAction(Actions.CREATE_STRIPE_PAYMENT_INTENT_REQUESTED))
    try{
        const result = await api.createStripePaymentIntent(amount)
        dispatch(makeAction(Actions.CREATE_STRIPE_PAYMENT_INTENT_SUCCEEDED))
        return result

    }catch (e){
        dispatch(makeAction(Actions.CREATE_STRIPE_PAYMENT_INTENT_FAILED,{},e))
    }
}

export const getInviteLink = member => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_INVITE_LINK_REQUESTED,{ member }))
    try{
        const result = await api.getInviteLink(member)
        dispatch(makeAction(Actions.GET_INVITE_LINK_SUCCEEDED, result))
        return result

    }catch (e){
        dispatch(makeAction(Actions.GET_INVITE_LINK_FAILED,{},e))
    }
}

export const sendInviteLink = member => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SEND_INVITE_LINK_REQUESTED, { member }))
    try{
        await api.sendInviteLink(member)
        dispatch(makeAction(Actions.SEND_INVITE_LINK_SUCCEEDED))
    }catch (e){
        dispatch(makeAction(Actions.SEND_INVITE_LINK_FAILED,{member},e))
        return e
    }
}