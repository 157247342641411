import React from 'react';
import { Document, Page, Image, Text, Link, View, Font, StyleSheet } from '@react-pdf/renderer';
import {sortBy} from "lodash/fp";
import {format, utcToZonedTime} from "date-fns-tz";
import OpenSans from "../../fonts/open-sans/static/OpenSans-Regular.ttf";
import OpenSansBold from "../../fonts/open-sans/static/OpenSans-Bold.ttf";
import ordinal from 'ordinal-number-suffix'

// PRO TIP: Use the REPL tool here https://react-pdf.org/repl to visually design this document
export const VisaInvitationLetterTemplate = ({visa, currentMoot}) => {
    const vemRed = '#dd1f26';
    
    const sortedOralSchedule = sortBy(item => item.date.value, currentMoot.MootRound)
    const startDate = new Date(sortedOralSchedule[0].date);
    // The startDate should equal the first oral date minus 1 day
    startDate.setUTCDate(startDate.getUTCDate() - 1);
    
    const dateRange = `${format(utcToZonedTime(startDate.toISOString(), 'Asia/Hong_Kong'), 'd MMMM yyyy')} through ${format(utcToZonedTime(currentMoot.mootEndDate, 'Asia/Hong_Kong'), 'd MMMM yyyy')}`;
   
    const styles = StyleSheet.create({
        body: {
            paddingTop: 25,
            paddingHorizontal: 30,
            paddingBottom: 30,
            fontFamily: 'OpenSans',
        },
        bodyText:{
            fontSize: 11,
            fontFamily: 'OpenSans',
            lineHeight: 2,
            marginBottom: 5,            
        },
        boldText:{
            fontWeight: 'bold'
        },        
        image: {
            height: 95,
            width: 95,
            alignSelf: 'center',
            marginBottom: 6
        },
        chop: {
            height: 65,
            width: 65,            
            marginBottom: 6,
            marginLeft: 6
        },
        subtitle: {
            fontSize: 12,
            marginBottom: 2,
            textAlign: 'center',
        },
        subtext:{
            fontSize: 9
        },
        box:{
            border: 1,
        },
        boxHeader:{            
            fontSize: 12,
            width: 225            
        },
        whiteText: {
            color : 'white'
        },
        footerText:{
            marginTop: 10,
            marginLeft: 15,
            fontSize: 9,
            color: vemRed,
            textAlign: 'center'
        },
        tableRow: {
            borderRight: 1, 
            borderLeft: 1, 
            borderTop:0, 
            borderBottom:1
        },
        tableCell: {
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 6,
            paddingRight: 6
        }

    });
    
    const date = format(new Date(),'dd LLL yyyy')
   

    Font.register({
        family: "OpenSans",
        fontStyle: "normal",
        fontWeight: "normal",
        fonts: [
          {
            src: OpenSans,
          },
          {
            src: OpenSansBold,
            fontWeight: "bold",
          },
        ],
      });

    

   
   
    return (
        <Document>
            <Page style={styles.body}>
                <Image
                    style={styles.image}
                    src="/logos/East-Vis-Moot-Logo-Red.png"
                />
                 <Text style={{textAlign: 'right', marginRight: 5, fontSize: 12, marginTop: 10}}>
                    {date}
                </Text>
                <Text style={[styles.subtitle,styles.boldText, {marginTop: 15, marginBottom: 15}]}>
                LETTER OF INVITATION
                </Text>

                <View style={{marginTop: 10}}>
                    <Text style={[styles.bodyText, {marginBottom: 20}]}>Dear Mesdames and Sirs,</Text>
                    <Text style={styles.bodyText}>The Vis East Moot Foundation Ltd. is delighted to issue this Letter of Invitation to the following individual, with the details below, to take part in the {ordinal(currentMoot.mootNumber)} Willem C. Vis East International Arbitration Moot, which will be held in Hong Kong S.A.R. from <Text style={[styles.boldText, {textDecoration: 'underline'}]}>{dateRange}</Text>:</Text>
                </View>

                <View style={{marginTop: 10, alignSelf: 'center'}}>
                    <View style={[styles.tableRow, {flexDirection: 'row', borderTop:1}]}>
                        <View style={[styles.boxHeader]}>
                            <Text style={[styles.tableCell, {borderRight: 1}]}>
                                Full Name:
                            </Text>
                        </View>
                        <View style={[styles.boxHeader]}>
                            <Text style={[styles.tableCell]}>
                                {visa.name}
                            </Text>
                        </View>
                    </View>
                    <View style={[styles.tableRow, {flexDirection: 'row'}]}>
                        <View style={[styles.boxHeader]}>
                            <Text style={[styles.tableCell, {borderRight: 1}]}>
                                Country:
                            </Text>
                        </View>
                        <View style={[styles.boxHeader]}>
                            <Text style={[styles.tableCell]}>
                                {visa.country}
                            </Text>
                        </View>
                    </View>
                    <View style={[styles.tableRow, {flexDirection: 'row'}]}>
                        <View style={[styles.boxHeader]}>
                            <Text style={[styles.tableCell, {borderRight: 1}]}>
                                Passport Number:
                            </Text>
                        </View>
                        <View style={[styles.boxHeader]}>
                            <Text style={[styles.tableCell]}>
                                {visa.passportNumber}
                            </Text>
                        </View>
                    </View>
                    <View style={[styles.tableRow, {flexDirection: 'row'}]}>
                        <View style={[styles.boxHeader]}>
                            <Text style={[styles.tableCell, {borderRight: 1}]}>
                                Expiration Date of Passport:
                            </Text>
                        </View>
                        <View style={[styles.boxHeader]}>
                            <Text style={[styles.tableCell]}>
                                {visa.expirationDate}
                            </Text>
                        </View>
                    </View>
                    <View style={[styles.tableRow, {flexDirection: 'row'}]}>
                        <View style={[styles.boxHeader]}>
                            <Text style={[styles.tableCell, {borderRight: 1}]}>
                                Date of Birth:
                            </Text>
                        </View>
                        <View style={[styles.boxHeader]}>
                            <Text style={[styles.tableCell]}>
                                {visa.dateOfBirth}
                            </Text>
                        </View>
                    </View>
                </View>

                <View style={{marginTop: 15}}>                    
                    <Text style={[styles.bodyText, {marginBottom: 30}]}>The Vis East Moot Foundation, Ltd. Looks forward to welcoming the above individual in Hong Kong and remains available to provide any further information or assistance that may be necessary. Should you have any questions, please do not hesitate to email us at: <Link href="mailto:info@cisgmoot.org" style={{color: '#1976d2'}}>info@cisgmoot.org</Link>.</Text>
                    <Text style={[styles.bodyText, {marginBottom: 0}]}>Yours Sincerely,</Text>
                    <Image
                    style={styles.chop}
                    src="/logos/VisEastMootCompany-chop.png"
                    />
                    <Text style={[styles.bodyText, styles.boldText, {lineHeight: 1.5, marginBottom: 0}]}>Vis East Moot Foundation, Ltd.</Text>
                    <Text style={[styles.bodyText, {lineHeight: 1.5, marginBottom: 0}]}>Mmes. Louise Barrington and Sherlin Tung</Text>
                    <Text style={[styles.bodyText, {lineHeight: 1.5, marginBottom: 0}]}>Directors</Text>
                </View>

                <View style={[styles.footerText, {marginTop: 50}]}>
                    <Text style={{marginBottom: 2}}>
                    Vis East Moot Foundation, Ltd.
                    </Text>
                    <Text style={{marginBottom: 2}}>
                    Room 106, The West Wing, Justice Place, Central, Hong Kong
                    </Text>
                    <Text style={{marginBottom: 2}}>
                        <Link href="https://www.cisgmoot.org" style={{color: vemRed}}>www.cisgmoot.org</Link>.
                    </Text>                    
                </View>
        
            </Page>
        </Document>
    )
}