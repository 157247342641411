import React from 'react'
import {Divider, Tooltip} from '@material-ui/core'
import {Edit, Delete, Send, Link, InfoOutlined} from '@mui/icons-material';
import {MenuItem} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ActionMenu from "../common/ActionMenu";
import _ from 'lodash/fp'

export default function TeamMemberActionMenu ({ onEdit = _.noop,
                                                  onEmailInvite =_.noop,
                                                  onCopyInvite = _.noop,
                                                  onDelete = _.noop,
                                                  allDisabled = false,
                                                  deleteDisabled = false,
                                                  inviteDisabled = false}) {

    const listItemTextStyle = {fontFamily: '"Avenir Medium", Arial, Helvetica, sans-serif', fontWeight: 'bold'}
    const listItemIconStyle = {minWidth:'30px'}

    return (
        <ActionMenu>
            <MenuItem key='edit' disabled={allDisabled} onClick={onEdit} disableRipple>
                <ListItemIcon style={listItemIconStyle}>
                    <Edit />
                </ListItemIcon>
                <ListItemText>
                    <Tooltip title='Accept'>
                        <span>Edit</span>
                    </Tooltip>
                </ListItemText>
            </MenuItem>

            <MenuItem key='delete' disabled={allDisabled} onClick={onDelete} disableRipple>
                <ListItemIcon style={listItemIconStyle}>
                    <Delete />
                </ListItemIcon>
                <ListItemText><span style={listItemTextStyle}>Delete</span></ListItemText>
            </MenuItem>
            <MenuItem key='send' disabled={allDisabled || inviteDisabled} disableRipple>
                <div style={{display: 'flex'}} onClick={onEmailInvite}>
                    <ListItemIcon style={listItemIconStyle}>
                        <Send />
                    </ListItemIcon>
                    <ListItemText><span style={listItemTextStyle}>Send invite</span></ListItemText>
                </div>
                <ListItemIcon style={{listItemIconStyle, marginLeft:'0.375rem'}}>
                    <Tooltip arrow title="Send team member the invite email to join this account" leaveTouchDelay={10000} enterTouchDelay={50}>
                            <InfoOutlined fontSize="inherit" />
                    </Tooltip>
                </ListItemIcon>
            </MenuItem>
            <MenuItem key='copy' disabled={allDisabled || inviteDisabled} disableRipple>
                <div style={{display: 'flex'}} onClick={onCopyInvite}>
                    <ListItemIcon style={listItemIconStyle}>
                        <Link />
                    </ListItemIcon>
                    <ListItemText><span style={listItemTextStyle}>Copy invite link</span></ListItemText>
                </div>
                <ListItemIcon style={{listItemIconStyle, marginLeft:'0.375rem'}}>
                    <Tooltip arrow title="Copy invite link to your clipboard" leaveTouchDelay={10000} enterTouchDelay={50}>
                            <InfoOutlined fontSize="inherit" />
                    </Tooltip>
                </ListItemIcon>
            </MenuItem>
        </ActionMenu>
    );
};