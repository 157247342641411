import * as actions from '../actions/teamActions'
import { isEmpty, get } from "lodash/fp";

export const getSelectedTeam = (hardRefresh = false) => async (dispatch,getState) => {
    let selectedTeam = get('team.selectedTeam',getState())
    if (hardRefresh || isEmpty(selectedTeam)){
        await (actions.getTeam())(dispatch,getState)
        selectedTeam = get('team.selectedTeam',getState())
    }
    return selectedTeam
}

export const getSelectedTeamsPairings = () => async (dispatch,getState) => {
    const selectedTeamPairings = get('team.selectedTeamsPairings',getState())
    if (isEmpty(selectedTeamPairings)){
        await (actions.getSelectedTeamsPairings())(dispatch,getState)
    }
}

export const getTeamMembers = () => async (dispatch,getState) => {
    await getSelectedTeam()(dispatch,getState)
    return get('team.members',getState())
}

export const getExistingClarifications = () => async (dispatch,getState) => {
    const existing = get('team.existingClarifications',getState())
    if (isEmpty(existing)){
        await (actions.getExistingClarifications())(dispatch,getState)
    }
}

export const getTeamPaymentHistory = (teamId, hardRefresh = false) => async (dispatch,getState) => {
    let paymentHistory = get('team.paymentHistory',getState())
    if (isEmpty(paymentHistory) || hardRefresh){
        paymentHistory = await (actions.getTeamPaymentHistory(teamId))(dispatch,getState)
    }
    return paymentHistory
}

export const getTeamById = teamId => async (dispatch,getState) => {
    let team = get('team.selectedTeam',getState())
    if (isEmpty(team) || team?.id !== teamId){
        team = await (actions.getTeamById(teamId))(dispatch,getState)
    }
    return team
}