import {connect} from "react-redux";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {Container, CircularProgress, Snackbar} from "@material-ui/core";
import {isEmpty, get, noop} from "lodash/fp";
import {Grid, Typography} from "@material-ui/core";
import Unauthorized from "../Unauthorized";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {getSelectedArbitrator} from "../../reducers/arbitratorSelectors";
import { ACCEPTED } from '../../consts/status.js'
import VisaInvitationLetterForm from "./VisaInvitationLetterForm"
import {getCurrentMoot} from "../../reducers/mootSelectors";
import {Alert} from "@mui/material";
import {uploadArbitratorVisaLetter} from "../../actions/fileActions";

const VisaInvitationLetter = ({
                      user,
                      currentMoot,
                      getSelectedArbitrator = noop,
                      getCurrentMoot = noop,
                      uploadArbitratorVisaLetter = noop
                  }) => {

    const [userInPerson, setUserInPerson] = useState()
    const [isLoading,setIsLoading] = useState(true);
    const [arbitratorData, setArbitratorData] = useState()
    const [snackbarBusy, setSnackbarBusy] = useState(false);
    const [snackbarSeverity,setSnackbarSeverity] = useState('info');
    const [snackbarMessage,setSnackbarMessage] = useState('');

    const userInPersonInfo = (user) => {
        if((user.participation === "I am available to BOTH rank written memoranda AND act as an arbitrator during oral arguments week" || 
            user.participation === "I am available to ONLY act as an arbitrator during oral arguments") && user.roundsYouCanArbitrate >= 4) {
            return user;
        }       
    }

    useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setIsLoading(true)
                await getCurrentMoot()
                const result = await getSelectedArbitrator()
                if (!isEmpty(result)) {
                    setArbitratorData(result)
                    const userInPersonData = userInPersonInfo(result);
                    setUserInPerson(userInPersonData)
                }
                           
                              
                setIsLoading(false)                
            } catch (e) {
                //TODO ?
                setIsLoading(false)
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
   
    
    //you have to be accepted to be allowed see this page
    const isAllowed = get('status',arbitratorData) === ACCEPTED

    const onlyGeneratedMessage = 'Only arbitrators who have committed to: (i) participate in oral arguments; and (ii) sit in a minimum of four (4) oral arguments, will be able to generate a Visa Invitation - Letter. Failure to abide by such commitments may result in the arbitrator from being barred from participating in future Vis East Moot Competitions.'
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarBusy(false)
    };

    return (
        <Container style={{paddingBottom: '10rem'}}>
            {isLoading &&
                <CircularProgress/>
            }
            {!isLoading && !isAllowed && 
                <Unauthorized />
            }
            {!isLoading && isAllowed &&
                <>                
                <Grid container spacing={2}>
                    <Grid item xs={12}>                        
                        <Typography variant='h4' component='h1' style={{ margin: '1rem 0'}}>Visa Invitation Letter</Typography>
                        
                        {userInPerson === undefined &&
                            <Typography variant='body1' style={{margin:'0'}}>{onlyGeneratedMessage}</Typography>
                        }

                        {(userInPerson !== undefined) &&
                        <>
                        <Typography>Please complete the below fields in order to generate a Visa Invitation Letter. <strong><u>ALL</u></strong> fields must be completed.</Typography>
                        <Typography style={{marginBottom:'0'}}>NOTES:</Typography>
                        <List style={{listStyle:'disc', paddingInlineStart: '40px'}}> 
                            <ListItem style={{display:'list-item', padding:'0'}}>
                                <Typography variant='body1' style={{margin:'0'}}>Names are based on the information inserted by the user during registration.</Typography>
                            </ListItem>
                            <ListItem style={{display:'list-item', padding:'0'}}>
                                <Typography variant='body1' style={{margin:'0'}}>{onlyGeneratedMessage}</Typography>
                            </ListItem>
                            <ListItem style={{display:'list-item', padding:'0'}}>
                                <Typography variant='body1' style={{margin:'0'}}>Participants are responsible for handling their own visa applications.</Typography>
                            </ListItem>  
                        </List> 
                        </>
                        }
                        <Snackbar open={snackbarBusy} onClose={handleClose} autoHideDuration={6000} >
                            <Alert severity={snackbarSeverity} sx={{ width: '100%' }}>
                                {snackbarMessage}
                            </Alert>
                        </Snackbar>
                    </Grid>
                </Grid>

                <VisaInvitationLetterForm user={user}
                                          userInPerson={userInPerson}
                                          type='ARBITRATOR_VISA'
                                          onUpload={(id,file,name) => uploadArbitratorVisaLetter(id,file,name)}
                                          onError={() => {
                    setSnackbarSeverity('error')
                    setSnackbarMessage('Error creating Visa Invitation Letter. Please try again.');
                    setSnackbarBusy(true)
                }} />

                </>
            }
        </Container>
    )
}

VisaInvitationLetter.propTypes = {
    getSelectedArbitrator: PropTypes.func
}

export default connect(
    (state) => ({
        currentMoot: state.moot.currentMoot,
        user: state.user
    }), {
        getSelectedArbitrator: getSelectedArbitrator,
        getCurrentMoot: getCurrentMoot,
        uploadArbitratorVisaLetter: uploadArbitratorVisaLetter

    })(VisaInvitationLetter)